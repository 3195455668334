
import { OAuthErrorResponse, ResponseError } from '@/app/error-output'

const OAUTH_BASE = process.env.NODE_ENV === 'development' ? (import.meta.env.VITE_PBX_BASE + '/auth') : `https://${window.location.host}/auth`

export type AuthData = {
	id: string
	password: string
}

export type TokenResponse = {
	access_token: string,
	expires_in: number,
	refresh_token: string,
	refresh_expires_in: number,
}

export default class OAuthLogin {

	static tokenEndpoint = '/realms/pbx/oauth2/token'
	static client_id = 'pbx-gui'

	private static refreshTimer: number | null = null

	#defaultFetchOptions: Record<string, unknown> = {
		headers: { Accept: 'application/json' },
		mode: 'cors'
	}

	messages: Record<string, string> = {
		invalid_grant: 'functionKeys.errorCodes.invalid_grant', // Invalid user credentials
		invalid_client: 'functionKeys.errorCodes.generic_oauth_error',
		unsupported_grant_type: 'functionKeys.errorCodes.generic_oauth_error'
	}

	constructor() {
		return this
	}

	getErrorMessageKey = (error: string | OAuthErrorResponse): string | null => {
		if (typeof (error) === 'string') {
			return this.messages[error] || error
		}

		if (error?.error) {
			console.log(error.error + ' - ' + error.error_description + ' - ' + error.code)
			return this.messages[error.error] || 'functionKeys.errorCodes.generic_oauth_error'
		}
		return 'functionKeys.errorCodes.generic_oauth_error'
	}

	async handleTokenResponseOrThrow(response: Response): Promise<TokenResponse> {
		if (response.ok) {
			const tokenData = await response.json()
			this.storeTokens(tokenData)
			return tokenData
		} else {
			this.storeTokens(null)
			throw this.getErrorMessageKey(await response.json())
		}
	}

	login = async (auth: AuthData, forceReLogin = false): Promise<ResponseError | boolean> => {
		if (forceReLogin) {
			return this.performLogin(auth)
		}

		return this.ensureActiveAccessToken()
			.then(async accessTokenValid =>
				accessTokenValid || await this.performLogin(auth)
			)
	}

	ensureActiveAccessToken(): Promise<boolean> {
		if (this.accessToken && !this.accessTokenExpired) {
			// already logged in
			return Promise.resolve(true)

		} else if (this.refreshToken && !this.refreshTokenExpired) {
			// invalid access token but refresh token is still valid, try to refresh the token
			return this.performRefresh()
		}
		return Promise.resolve(false)
	}

	performLogin(auth: AuthData): Promise<boolean> {
		const tokenRequest = {
			grant_type: 'password',
			client_id: OAuthLogin.client_id,
			username: auth.id,
			password: auth.password
		}

		const requestBody = new URLSearchParams(tokenRequest)

		return fetch(OAUTH_BASE + OAuthLogin.tokenEndpoint, {
			...this.#defaultFetchOptions,
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: requestBody
		})
			.then(async (response) => this.handleTokenResponseOrThrow(response))
			.then((data) => {
				console.debug('Login succeeded, token is valid until ' + new Date((new Date().getTime() / 1000 + data.expires_in) * 1000).toLocaleTimeString())
				return true
			})
	}

	performRefresh = (refresh_token?: string): Promise<boolean> => {
		// the refresh taken may be "created" by the Windows or MacOS app, so we need to use
		// their client_id to refresh the token
		const client_id = this.extractClientIdFromJwtToken(refresh_token || this.refreshToken)

		const tokenRequest = {
			grant_type: 'refresh_token',
			client_id: client_id || OAuthLogin.client_id,
			refresh_token: refresh_token || this.refreshToken || ''
		}

		return fetch(OAUTH_BASE + OAuthLogin.tokenEndpoint, {
			...this.#defaultFetchOptions,
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: new URLSearchParams(tokenRequest)
		})
			.then(async (response) => this.handleTokenResponseOrThrow(response))
			.then((data) => {
				console.debug('Token refreshed, is valid until ' + new Date((new Date().getTime() / 1000 + data.expires_in) * 1000).toLocaleTimeString())
				return true
			})
	}

	storeTokens(tokenResponse: TokenResponse | null) {
		if (tokenResponse?.access_token) {
			localStorage.setItem('access_token', tokenResponse.access_token)
			localStorage.setItem('access_token_exp', '' + (new Date().getTime() / 1000 + tokenResponse.expires_in))
		} else {
			localStorage.removeItem('access_token')
			localStorage.removeItem('access_token_exp')
		}
		if (tokenResponse?.refresh_token) {
			localStorage.setItem('refresh_token', tokenResponse.refresh_token)
			localStorage.setItem('refresh_token_exp', '' + (new Date().getTime() / 1000 + tokenResponse.refresh_expires_in))
		} else {
			localStorage.removeItem('refresh_token')
			localStorage.removeItem('refresh_token_exp')
		}
	}

	removeTokens() {
		localStorage.removeItem('access_token')
		localStorage.removeItem('access_token_exp')
		localStorage.removeItem('refresh_token')
		localStorage.removeItem('refresh_token_exp')
	}

	get accessToken(): string | null {
		return localStorage.getItem('access_token')
	}

	set accessToken(accessToken: string | null) {
		if (accessToken) {
			localStorage.setItem('access_token', accessToken)
		} else {
			localStorage.removeItem('access_token')
		}
	}

	get refreshToken(): string | null {
		return localStorage.getItem('refresh_token')
	}

	set refreshToken(refreshToken: string | null) {
		if (refreshToken) {
			localStorage.setItem('refresh_token', refreshToken)
		} else {
			localStorage.removeItem('refresh_token')
		}
	}

	get refreshTokenExpired(): boolean {
		if (!this.refreshToken) {
			return true
		}
		const expirationTime = parseFloat(localStorage.getItem('refresh_token_exp') || '0')
		return (expirationTime - 30) < new Date().getTime() / 1000
	}

	get accessTokenExpired(): boolean {
		if (!this.accessToken) {
			return true
		}
		const expirationTime = parseFloat(localStorage.getItem('access_token_exp') || '0')
		return (expirationTime - 20) < new Date().getTime() / 1000
	}

	get accessTokenExpirationSecondsLeft(): number {
		if (!this.accessToken) {
			return 0
		}
		const expirationTime = parseFloat(localStorage.getItem('access_token_exp') || '0')
		return expirationTime - new Date().getTime() / 1000
	}

	status401OnRequestOccured() {
		// remove only access token, refresh token may be still valid
		localStorage.removeItem('access_token')
		localStorage.removeItem('access_token_exp')
	}

	registerAutoRefresh() {
		if (OAuthLogin.refreshTimer) {
			window.clearTimeout(OAuthLogin.refreshTimer)
		}

		OAuthLogin.refreshTimer = window.setTimeout(async () => {
			if (await this.performRefresh()) {
				this.registerAutoRefresh()
			}
		}, (this.accessTokenExpirationSecondsLeft - 20) * 1000);
	}

	private extractClientIdFromJwtToken(jwtToken: string | null) : string | null {
		if (!jwtToken) {
			return null
		}

		try {
			return JSON.parse(atob(jwtToken?.split('.')[1]))['azp']
		} catch (e) {
			return null
		}
	}

}
